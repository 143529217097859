import React from "react"
import Headline from "../headline/headline"
import CoverMedia from "../cover-media/cover-media"

const NextArticle = ({work, cropped}) => {
  return (
    <a href={'/works/' + work.url} className="block bg-regular pt-24">
      <Headline {...work?.headline} />
      <CoverMedia {...work} cropped={cropped}/>
    </a>
  )
}

export default NextArticle
