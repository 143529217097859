import React from "react"
import "./headline.scss"
import Logo916 from "../../../images/logo-916.svg"
import PlusIcon from "../../../images/icons/plus-icon.svg"

const Headline = ({ heading, subheading, partnerLogo, categories }) => {

  const HeadlineLogos = () => {
    return (
      <div className="headline__logos">
        <img src={Logo916} alt="9-16 Logo" />
        {
          partnerLogo?.url
            ? <>
                <img className="headline__plus-icon mx-8" src={PlusIcon} alt="" />
                <img src={partnerLogo.url} alt={partnerLogo.alternativeText || ""} />
              </>
            : ""
        }
      </div>
    )
  }

  return (
    <div className="headline">
      <div className="container">
        <HeadlineLogos />
        <h1 className="headline__title">{ heading }</h1>
        <h2 className="headline__subtitle">{ subheading }</h2>
        <div className="headline__tags">{ categories }</div>
      </div>
    </div>
  )
}

export default Headline
