import React from "react"
import './works-content.scss'
import RichText from "../../shared/rich-text/rich-text"

const WorksContent = ({lead, content}) => {
  if (!lead && !content) return ""

  return (
    <div className="works-content">
      {lead ? <RichText wrapperClassNames="lead" data={lead} slim={true}/> : ""}
      {content ? <RichText wrapperClassNames="content" data={content} slim={true} /> : ""}
    </div>
  )
}

export default WorksContent
