import React from "react"
import './cover-media.scss'

const CoverMedia = ({headerImage, headerVideo, cropped}) => {
  const getVideoSrc = (srcLink) => {
    /* if the link is in format1 https://player.vimeo.com/video/{VideoID}?h=...
    *    directly return it.
    * if the link is in format2 https://vimeo.com/156132783
    *   get the videoId and return the link which is converted to format1
    * */
    if (srcLink.includes('player.vimeo.com/')) return srcLink;
    const videoID = srcLink.split('vimeo.com/')[1];
    const embedLink = `https://player.vimeo.com/video/${videoID}?h=9f3e677703`;
    return embedLink || srcLink;
  }

  return (
    <div className={`cover-media container ${cropped ? "cropped" : ""}`}>
      {headerVideo ?
        <iframe className="video-wrapper w-full"
                src={getVideoSrc(headerVideo)}
                title={headerImage?.alternativeText || ""}
        />
        : <img className="cover-image" src={headerImage?.url || ""} alt={headerImage?.alternativeText || ""}/>
      }
    </div>
  )
}

export default CoverMedia
