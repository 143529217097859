import React, { useLayoutEffect, useRef, useState } from "react"
import "../works-tile/works-tile.scss"
import Rellax from "rellax"
import VerticalSlider from "../works-tile/vertical-slider"

const TileGallery = ({
                       gallery,
                       options,
                       view = "currentLightBox",
                       paddingClass = "px-0 pb-20 lg:pb-96 xl:pb-96 2xl:pb-96 py-8",
                       setVideoLink
                     }) => {
  const { animate } = options
  const [windowWidth, setWindowWidth] = useState(0)
  const [sliders, setSliders] = useState([])
  const wrapperDiv = useRef()
  const config = Object.assign({
    verticalGrids: windowWidth > 1024 ? 6 : windowWidth > 768 ? 5 : 3,
    itemsPerSlider: 6,
    timeoutForMovingSliders: 100
  }, (options || {}))

  const gridTemplateColumns = `repeat(${config.verticalGrids}, ${100 / (config.verticalGrids - 2)}%)`
  const marginLeft = `-${100 / (config.verticalGrids - 2)}%`

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth)
      sliders.forEach(slid => {
        slid.refresh()
      })
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
    // eslint-disable-next-line
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!animate) return
      const wWidth = window.innerWidth
      if (wWidth < 1024) return
      const speeds2XL = [-2.5, -4.5, -4.0, -2.0, -3.0, -3.6] // > 1536
      const speedsXL = [-2.2, -3.7, -3.4, -1.8, -2.6, -3.0] // 1536 - 1280
      const speedsLG = [-2.0, -2.2, -2.3, -1.8, -2.0, -2.2] // 1280 - 1024
      const speedsMD = [-2.0, -2.2, -2.3, -1.8, -2.0, -2.2] // 768 - 1024
      const speedsSM = [-2.0, -2.2, -2.3, -1.8, -2.0, -2.2] // 640 - 768
      const speedsXS = [-0.0, -0.0, -0.8, -0.2, -0.9, -0.0] // < 640


      let speeds = []
      if (wWidth >= 1536) {
        speeds = speeds2XL
      } else if (wWidth < 1536 && wWidth >= 1280) {
        speeds = speedsXL
      } else if (wWidth < 1280 && wWidth >= 1024) {
        speeds = speedsLG
      } else if (wWidth < 1024 && wWidth >= 768) {
        speeds = speedsMD
      } else if (wWidth < 768 && wWidth >= 640) {
        speeds = speedsSM
      } else speeds = speedsXS


      speeds.forEach((val, index) => {
        const slider = new Rellax(`.works-tile__vertical-slider--${index}`, { speed: val })
        setSliders([...sliders, slider])
      })
    }, 2000)
    // eslint-disable-next-line
  }, [animate])

  return (
    <>
      <div ref={wrapperDiv}
           className={"works-tile works-tile__content-container container " + paddingClass + (!animate ? " no-animate" : "")}>
        <div
          className={`works-tile__content-wrapper ${!animate ? "no-transform" : ""}`}
          style={{
            gridTemplateColumns,
            marginLeft
          }}
        >
          {[...new Array(config.verticalGrids)].map((grid, index) => {
            const items = gallery[index]
            if (!items?.[0]?.thumbnail?.url) return ""
            return (
              <VerticalSlider
                key={`slider-${index}`}
                index={index}
                data={items}
                animate={animate}
                view={view}
                setVideoLink={setVideoLink}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TileGallery
