import React from "react"
import "./image-gallery.scss"

const ImageGallery = ({ data }) => {
  const validItems = data.filter(item => item.url)
  if (!validItems.length) return ""
  return (
    <div className="image-gallery container my-8">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="row">
            {
              validItems.map((item, index) =>
                <button key={index} className="col-6 col-md-4 p-1"
                        onClick={() => {window.currentGalleryLightBox.open(validItems, index)}}
                >
                  <img className="thumbnail" src={item.url} alt={item.alternativeText} />
                </button>
              )
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default ImageGallery
