import React from "react"
import { graphql } from "gatsby"
import BackDropped from "../../../components/shared/back-dropped/back-dropped"
import "../../../components/insights/insights.scss"
import Headline from "../../../components/works/headline/headline"
import CoverMedia from "../../../components/works/cover-media/cover-media"
import WorksContent from "../../../components/works/content/works-content"
import WorksTiles from "../../../components/works/works-tiles/works-tiles"
import BasicLayout from "../../../components/layout/basic-layout"
import ImageGallery from "../../../components/works/image-gallery/image-gallery"
import ArticleFooter from "../../../components/insights/article-footer/article-footer"
import NextArticle from "../../../components/works/next-article/next-article"
import NotFoundPage from "../../404"

const WorkDetail = ({ data, locale="tr" }) => {
  const work = data.strapiWorks || {pageSettings: {}}
  const pageSettings = work.pageSettings
  const articlesData = data.allStrapiWorks
  let nextArticle = articlesData?.edges?.find(x => x.node.url === work.url)?.next
  if (!work?.url) return <NotFoundPage />

  return <>
    <BasicLayout
      grid={false}
      theme={pageSettings.headerTheme}
      path="works"
      seo={pageSettings}
      wrapperClass="bg-light">
      <Headline {...work.headline} />
      <CoverMedia {...work} />
      <WorksContent lead={work.lead} content={work.content1} />
      {work.tileGallery[0]?.items?.[0].thumbnail?.url ? <WorksTiles data={work.tileGallery} view={"currentVidBox"}/> : ""}
      <WorksContent content={work.content2} />
      {work.gallery ? <ImageGallery data={work.gallery} /> : ""}
      <WorksContent content={work.content3} />
      <ArticleFooter title={work.headline.heading} url={work?.url} locale={locale}/>
      { nextArticle ? <NextArticle work={nextArticle} cropped/> : "" }
    </BasicLayout>

    <BackDropped locale={locale} />
  </>
}


export const query = graphql`
    query WorksQueryTR($url: String!){
        strapiWorks(url:{eq: $url} locale: {eq: "tr"}) {
            ...PageDataWorkDetail
        }
        allStrapiWorks {
            edges {
                next {
                    url
                    headerImage {
                        alternativeText
                        url
                    }
                    headline {
                        categories
                        heading
                        subheading
                        partnerLogo {
                            url
                            alternativeText
                        }
                    }
                }
                node {
                    url
                }
            }
            nodes {
                url
            }
        }
    }
`


export default WorkDetail
