import React from "react"
import "./works-tiles.scss"
import TileGallery from "../../common/tile-gallery/tile-gallery"

const WorksTiles = ({ data = [], view, setVideoLink }) => {
  const gallery = data.map((col, index) => {
    return col.items
  })
  return (
    <div className="works-tile-gallery py-20" id="works-tiles">
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <TileGallery gallery={gallery} options={{ animate: false }} paddingClass="px-0 pb-12" view={view}
                         setVideoLink={setVideoLink} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default WorksTiles
